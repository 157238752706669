import './assets/css/tailwind.output.css';

import { Amplify } from 'aws-amplify';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Home from './components/Home';
import config from './utils/config';

Amplify.configure(config);

const App = () => {
    return (
        <Router>
            <Home />
        </Router>
    );
};

export default App;
