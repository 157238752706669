import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import packageInfo from '../package.json';
import App from './App';
import * as serviceWorker from './serviceWorker';

const { REACT_APP_ENVIRONMENT: ENV, NODE_ENV } = process.env;

if (NODE_ENV === 'production') {
    Sentry.init({
        environment: ENV,
        release: packageInfo.version,
        dsn: 'https://f69a0d3063c5424b84db3fa9be854ef7@o421207.ingest.sentry.io/5404676',
    });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
