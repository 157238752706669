import { Auth } from 'aws-amplify';

export const AUTH_STATUS = {
    NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
    SMS_MFA: 'SMS_MFA',
    SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
    LOGGED_IN: 'LOGGED_IN',
};

export const getSession = async () => {
    try {
        const session = await Auth.currentSession();

        return {
            tokens: {
                access: session.accessToken.jwtToken,
                id: session.idToken.jwtToken,
                refresh: session.refreshToken.token,
            },
            username: session.idToken.payload['cognito:username'],
            email: session.idToken.payload.email,
        };
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const signIn = async (username, password) => {
    const user = await Auth.signIn(username, password);

    return {
        status: user.challengeName || AUTH_STATUS.LOGGED_IN,
        user,
    };
};

export const completeNewPassword = async (user, newPassword) => {
    const result = await Auth.completeNewPassword(user, newPassword);

    return {
        status: result.challengeName || AUTH_STATUS.LOGGED_IN,
        user,
    };
};

export const forgotPassword = (username) => {
    return Auth.forgotPassword(username);
};

export const resetPassword = (username, code, newPassword) => {
    return Auth.forgotPasswordSubmit(username, code.toString(), newPassword);
};

export const confirmMFACode = (user, code, mfaType) => {
    return Auth.confirmSignIn(user, code.toString(), mfaType);
};

export const signOut = () => {
    return Auth.signOut();
};
