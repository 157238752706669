import React from 'react';

import { prettyString } from '../../utils/shared';

export default function Tab({
    color = 'gray',
    children = 'N/A',
    snug = false,
    prettify = true,
    wrap = false,
}) {
    return (
        <span
            className={`inline-flex items-center font-normal rounded text-xs ${
                snug ? 'px-1' : 'px-2 py-1'
            } mr-1 ${
                wrap ? 'mb-1' : ''
            } bg-${color}-100 text-${color}-700 border border-${color}-700`}
        >
            {children ? (prettify ? prettyString(children) : children) : 'N/A'}
        </span>
    );
}
