import { Form, Formik } from 'formik';
import React from 'react';

import Input from '../shared/forms/Input';
import SubmitButton from '../shared/forms/SubmitButton';

const ForgotPassword = ({ didError, onBackToSignIn, onForgotPassword }) => {
    const initialValues = {
        userName: '',
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onForgotPassword}>
            {({ isSubmitting }) => {
                return (
                    <Form className="text-left">
                        <p className="text-xl font-light text-teal-600 border-b border-teal-600 pt-2 mb-4">
                            Forgot Password
                        </p>

                        <Input
                            name="userName"
                            label="User Name"
                            placeholder="e.g. jane.smith"
                            disabled={isSubmitting}
                        />

                        <p className="text-sm mb-6">
                            <button
                                type="button"
                                className="text-teal-600 underline"
                                onClick={onBackToSignIn}
                            >
                                Back to sign in
                            </button>
                        </p>

                        {didError && (
                            <p className="text-sm text-center text-red-700 mb-4">
                                Something went wrong. Please try again.
                            </p>
                        )}

                        <SubmitButton isSubmitting={isSubmitting} text="Send Code" />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ForgotPassword;
