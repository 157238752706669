import { Form, Formik } from 'formik';
import React from 'react';

import Input from '../shared/forms/Input';
import SubmitButton from '../shared/forms/SubmitButton';

const CompleteNewPassword = ({ didError, onCompleteNewPassword }) => {
    const initialValues = {
        newPassword: '',
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onCompleteNewPassword}>
            {({ isSubmitting }) => {
                return (
                    <Form className="text-left">
                        <p className="text-xl font-light text-teal-600 border-b border-teal-600 pt-2 mb-4">
                            Complete New Password
                        </p>

                        <Input
                            name="newPassword"
                            type="password"
                            label="New Password"
                            autocomplete="new-password"
                            disabled={isSubmitting}
                        />

                        {didError && (
                            <p className="text-sm text-center text-red-700 mb-4">
                                Something went wrong. Please try again.
                            </p>
                        )}

                        <SubmitButton isSubmitting={isSubmitting} text="Set New Password" />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CompleteNewPassword;
