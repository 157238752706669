import { Minus, Plus, X } from 'heroicons-react';
import React from 'react';

import Loader from './Loader';
import Tag from './Tag';

const roleMappings = {
    hcp: 'HCP',
    org_admin: 'Admin',
    controller: 'Controller',
    superuser: 'Superuser',
};

export default function ManagePermissions({ org, updatingPermissions, updatePermissions, groups }) {
    const [showRoleList, setShowRoleList] = React.useState(false);

    const permissionsHeld = [];
    const permissionsAvailable = [];

    ['hcp', 'org_admin', 'controller', 'superuser'].forEach((role) => {
        if (groups.includes(`${role}-${org}`)) {
            permissionsHeld.push(role);
        } else {
            permissionsAvailable.push(role);
        }
    });

    return (
        <div className="flex items-center justify-start">
            {permissionsHeld.map((role) => (
                <Tag wrap prettify={false} key={role}>
                    {roleMappings[role]}
                    {updatingPermissions ? (
                        <div className="transform translate-x-1">
                            <Loader />
                        </div>
                    ) : (
                        <button
                            className="rounded p-1 font-light text-red-700 hover:bg-red-700 hover:text-white flex text-sm items-center transform translate-x-1"
                            onClick={() =>
                                updatePermissions({
                                    remove: [`${role}-${org}`],
                                })
                            }
                        >
                            <X size={10} />
                        </button>
                    )}
                </Tag>
            ))}
            {permissionsAvailable.length > 0 && (
                <div className="relative">
                    <button
                        className="rounded p-1 bg-gray-200 font-light text-green-700 hover:bg-gray-300 inline-flex text-sm items-center mb-1"
                        onClick={() => setShowRoleList(!showRoleList)}
                    >
                        {showRoleList ? <Minus size={20} /> : <Plus size={20} />}
                    </button>
                    {showRoleList && (
                        <div className="absolute bg-white shadow-md rounded p-2 mt-2 z-10">
                            {permissionsAvailable.map((role) => (
                                <button
                                    className="rounded py-1 px-2 bg-gray-200 font-light text-green-700 hover:bg-gray-300 inline-flex text-sm items-center mb-1 w-full"
                                    onClick={() =>
                                        updatePermissions({
                                            add: [`${role}-${org}`],
                                        })
                                    }
                                    key={role}
                                >
                                    {roleMappings[role]}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
