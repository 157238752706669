import { format, isDate, isValid, parseISO } from 'date-fns';

export const prettyDate = (value) => {
    const dt = parseISO(value);

    return isDate(dt) && isValid(dt) ? format(dt, 'dd/MM/yyyy') : null;
};

export const prettyDateTime = (value) => {
    const dt = parseISO(value);

    return isDate(dt) && isValid(dt) ? format(dt, "HH:mm 'on' dd/MM/yyyy") : null;
};

const capitalize = (string) => (string ? string.charAt(0).toUpperCase() + string.slice(1) : '');

export const prettyString = (string) =>
    capitalize(string ? string.toLowerCase().replace(/_/g, ' ') : '');
