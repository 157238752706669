export const configs = {
    prod: {
        aws_user_pools_id: 'eu-west-2_7szLxGGnP',
        aws_user_pools_web_client_id: '6as98ulhqcgvoqgq8lbaf7ui6',
    },
    staging: {
        aws_user_pools_id: 'eu-west-2_PvHtGtdEh',
        aws_user_pools_web_client_id: '53njnrla4ot3jqeofstuj0d2ba',
    },
    dev: {
        aws_user_pools_id: 'eu-west-2_8nSfpdXYL',
        aws_user_pools_web_client_id: '34jnq67ffliuvfov769u8ssgov',
    },
    js: {
        aws_user_pools_id: 'eu-west-2_kWHZXRlLo',
        aws_user_pools_web_client_id: '3bdqndgu04t0pt9up43gmbvl90',
    },
    sandbox1: {
        aws_user_pools_id: 'eu-west-2_kuuNdwvqa',
        aws_user_pools_web_client_id: '3qr8q379uuivdq3u86e6lg94bq',
    },
    sandbox2: {
        aws_user_pools_id: 'eu-west-2_QvAgPNclX',
        aws_user_pools_web_client_id: '4mf7vgrgcucocdmfgp5htg9fsb',
    },
    sandbox3: {
        aws_user_pools_id: 'eu-west-2_1EXvPAMD1',
        aws_user_pools_web_client_id: '2it3c7hsfofg6gfg0qsah3o3kp',
    },
    wb: {
        aws_user_pools_id: 'eu-west-2_eMHqkOnKx',
        aws_user_pools_web_client_id: '5pimi79t9qa8p9hkgtntfpfum0',
    },
    bz: {
        aws_user_pools_id: 'eu-west-2_zXEk0pZSu',
        aws_user_pools_web_client_id: '3ftu5n20rblfae68f80757laht',
    },
};

const { REACT_APP_ENVIRONMENT: env } = process.env;
const subdomainPrefix = env === 'prod' ? '' : `${env}-`;

const config = {
    ...configs[env],
    apiEndpoint: `https://${subdomainPrefix}mgmt.docabode.com/api`,
};

export default config;
