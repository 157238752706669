import React from 'react';

import Loader from '../Loader';

const SubmitButton = ({ isSubmitting = false, text = 'Submit', onSubmit }) => {
    return (
        <button
            className={`block w-full rounded bg-teal-600 text-white p-6 ${
                isSubmitting ? 'cursor-not-allowed' : ''
            }`}
            disabled={isSubmitting}
            type="submit"
            onClick={onSubmit}
        >
            {isSubmitting && (
                <div className="inline-flex items-center">
                    <Loader />
                    Please wait...
                </div>
            )}
            {!isSubmitting && text}
        </button>
    );
};

export default SubmitButton;
