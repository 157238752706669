import { useField } from 'formik';
import React from 'react';

const Input = ({
    children,
    disabled = false,
    label,
    name,
    placeholder,
    type = 'text',
    ...props
}) => {
    const [field, meta] = useField({
        ...props,
        name,
        placeholder,
        type,
    });

    const showError = meta.touched && meta.error;

    return (
        <div className="mb-4">
            <label className="block">
                <span className={`${showError ? 'text-red-700' : 'text-gray-700'}`}>{label}</span>
                <input
                    className={`form-input mt-1 block w-full ${
                        showError ? 'border-red-700 text-red-700' : ''
                    } ${disabled ? 'opacity-75' : ''}`}
                    {...field}
                    placeholder={placeholder}
                    type={type}
                    disabled={disabled}
                />
            </label>
            {children && <div className="mt-2 text-xs opacity-75">{children}</div>}
            {showError && <div className="text-sm text-red-700 mt-2">{meta.error}</div>}
        </div>
    );
};

export default Input;
